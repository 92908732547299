/* eslint-disable */
import notify from '@/util/notify';

export default {
  /**
  * Global notification helper. Accessible via this.$notify
  */
  notify,
  install(Vue, options) {
    Vue.prototype.$notify = notify;
  },
};
