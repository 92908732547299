import { EventBus } from "@/event-bus";

export default {
  error: (text) => {
    EventBus.$emit("notify", "error", text);
  },

  info: (text) => {
    EventBus.$emit("notify", "info", text);
  },

  success: (text) => {
    EventBus.$emit("notify", "success", text);
  },

  warning: (text) => {
    EventBus.$emit("notify", "warning", text);
  },
};
