<template>
  <div>
    <router-view></router-view>
    <v-alert
      v-model="globalAlert.show"
      :color="globalAlert.color"
      :icon="false"
      dense
      dismissible
      elevation="5"
      close-icon="fas fa-times font-size-root"
      style="
        width: 50%;
        position: fixed;
        bottom: 0%;
        margin: 5% auto;
        left: 0;
        right: 0;
        color: white;
      "
    >
      {{ globalAlert.text }}
    </v-alert>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  name: "app",
  data() {
    return {
      globalAlert: {
        show: false,
        text: "",
        color: "#11cdef",
      },
    };
  },
  methods: {
    handleNotificationEvents() {
      EventBus.$on("notify", (type, message) => {
        switch (type) {
          case "success": {
            this.globalAlert.color = "#44ab4a";
            break;
          }
          case "warning": {
            this.globalAlert.color = "#fb6340";
            break;
          }
          case "error": {
            this.globalAlert.color = "#f53636";
            break;
          }
          default: {
            this.globalAlert.color = "#11cdef";
            break;
          }
        }

        this.globalAlert.text = message;
        this.globalAlert.show = true;

        setTimeout(() => {
          this.globalAlert.show = false;
        }, 5000);
      });
    },
  },
  mounted() {
    this.handleNotificationEvents();
  },
};
</script>
