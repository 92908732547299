import { defineStore } from "pinia";

export const useSessionStore = defineStore("session", {
  state: () => ({
    user: {
      userId: "",
      profileImageUri: "",
      firstName: "",
      lastName: "",
      email: "",
      roles: [],
      status: "inactive",
    },
    token: null,
    headers: {},
    config: {},
    httpHeaders: {},
    httpConfig: {
      headers: {},
      baseURL: "",
    },
    apiHost: "",
  }),
  getters: {
    getUser(state) {
      return state.user;
    },
    getUserDisplayName(state) {
      return state.user.firstName;
    },
    getUserProfileImageUri(state) {
      return state.user.profileImageUri;
    },
    getUserRoles(state) {
      return state.user.roles;
    },
    getToken(state) {
      return state.token;
    },
    getHeaders(state) {
      return state.headers;
    },
    getConfig(state) {
      return state.config;
    },
    getHttpConfig(state) {
      return state.httpConfig;
    },
    getApiHost(state) {
      return state.apiHost;
    },
  },
  actions: {
    setPostLogin(
      token,
      userId,
      profileImageUri,
      firstName,
      lastName,
      email,
      roles = [],
      status = "inactive"
    ) {
      this.token = token;
      this.user.userId = userId;
      this.user.profileImageUri = profileImageUri;
      this.user.firstName = firstName;
      this.user.lastName = lastName;
      this.user.email = email;
      this.user.status = status;

      this.user.roles.splice(0);
      for (let i = 0; i < roles.length; i += 1) {
        this.user.roles.push(roles[i]);
      }
    },

    setBaseUrl(baseUrl) {
      this.httpConfig.baseURL = baseUrl;
    },

    setHttpHeaders(httpHeaders) {
      this.httpConfig.headers = httpHeaders;
    },

    setApiHost(apiHost) {
      this.apiHost = apiHost;
    },

    unsetSession() {
      this.user = {
        userId: "",
        profileImageUri: "",
        firstName: "",
        lastName: "",
        email: "",
        roles: [],
      };
      this.token = null;
      this.headers = {};
      this.config = {};
      this.httpConfig = {
        headers: {},
        baseURL: "",
      };
      this.apiHost = "";
    },
  },
  persist: {
    key: "st",
  },
});
