var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"py-4 px-2 toolbar-content-padding-y-none",class:{ 'border-bottom': !_vm.hasBg, 'border-bottom-dark': _vm.hasBg },attrs:{"color":_vm.background,"dark":"","height":"auto","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{staticClass:"font-weight-600 text-capitalize drawer-toggler py-3 px-6 rounded-sm",class:{
          'btn-dark-toggler-hover': !_vm.hasBg,
          'btn-toggler-hover': _vm.hasBg,
          active: _vm.togglerActive,
        },attrs:{"elevation":"0","ripple":false,"height":"43","color":"transparent"},on:{"click":_vm.drawerClose}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line",class:{ 'bg-white': _vm.hasBg }}),_c('i',{staticClass:"drawer-toggler-line",class:{ 'bg-white': _vm.hasBg }}),_c('i',{staticClass:"drawer-toggler-line",class:{ 'bg-white': _vm.hasBg }})])]):_vm._e(),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","position-x":50},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-600 ls-0 text-capitalize pe-0",attrs:{"depressed":"","ripple":false,"color":"transparent"}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"me-2 rounded-circle",attrs:{"width":"36","src":_vm.sessionStore.getUserProfileImageUri}}),_c('span',{class:{ 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },style:(!_vm.hasBg ? 'color: rgba(0,0,0, .6)' : '')},[_vm._v(_vm._s(_vm.sessionStore.getUserDisplayName))])],1)]}}])},[_c('v-list',{attrs:{"min-width":"192","max-width":"192"}},[_c('h6',{staticClass:"text-h6 px-4 font-weight-600 text-typo text-uppercase py-2"},[_vm._v(" Welcome! ")]),_vm._l((_vm.dropdown3),function(item,i){return _c('v-list-item',{key:i,staticClass:"min-height-auto",class:{ 'border-bottom': i == _vm.dropdown3.length - 2 },attrs:{"link":"","ripple":false},on:{"click":function($event){return _vm.actionListItem(item)}}},[_c('v-list-item-avatar',{staticClass:"my-0 me-5",attrs:{"width":"15","min-width":"15","height":"37"}},[_c('v-icon',{staticClass:"text-darker"},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-darker"},[_vm._v(_vm._s(item.name))])],1),_c('div',{staticClass:"border-bottom"})],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }