<template>
  <v-app>
    <v-main class="auth-pages bg-default">
      <div class="header-auth position-relative auth-background-top">
        <div class="separator separator-bottom separator-skew zindex-100">
          <svg
            x="0"
            y="0"
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              class="fill-default"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <app-bar-auth background="transparent" has-bg linkColor="white">
      </app-bar-auth>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container class="mt-n16 pb-0">
          <router-view></router-view>
          <content-footer auth v-if="!$route.meta.hideFooter"></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<style>
.auth-background-top {
  background: linear-gradient(87deg, rgb(0 73 4) 0, #6b8a00 100%) !important;
}
</style>

<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "auth-layout",
  components: {
    AppBarAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      paragraphs: "",
    };
  },
  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "Pricing":
          return "Choose the best plan for your business";
        case "Login":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Welcome!";
        case "Register":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Create an account";
        case "Lock":
          this.paragraphs = "Better to be safe than sorry.";
          return "Lock screen";
        default:
          break;
      }
    },
  },
};
</script>
